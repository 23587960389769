export const TUNER_ROUTE = '/tuner';
export const RELATIVE_PITCH_TASKS_ROUTE = '/pitch';
export const INTERVAL_TASKS_ROUTE = '/interval';
export const MELODY_TASKS_ROUTE = '/melody';
export const UNIVERSAL_TASKS_ROUTE = '/all';
export const CALIBRATE_ROUTE = '/calibrate';
export const TEST_FORM_ROUTE = '/test-form';
export const SIGNIN_ROUTE = '/signin';
export const SIGNUP_ROUTE = '/signup';
export const DASHBOARD_ROUTE = '/dashboard';
export const STUDY_ROUTE = '/study/:studyId';
export const AUDIO_TEST_ROUTE = '/audio-test';
export const AUDIO_TEST_PHONE_ROUTE = '/phone';
export const HOME_ROUTE = '/';
export const ADMIN_ROUTE = '/admin';
